'use strict';

var _ = require('lodash');
var AddressAutocompleteDialog;
var AddressVerificationDialog;
var AddressVerificationHelper = require('../../../../bc_sheplersbootbarn_core/cartridge/js/AddressVerification/AddressVerificationHelper');
var ApplePay = require('./checkout/ApplePay');
var CheckoutConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/CheckoutConstants');
var GiftCard = require('./checkout/GiftCardPaymentPanel');
var loadingIndicator = require('../components/LoadingIndicator');
var PaymentMethodConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/PaymentInstrumentConstants').PaymentMethods;
var RewardsCredit = require('./checkout/RewardsCredit');
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var TemplateConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var tooltip = require('../tooltip');
var TPromise = require('promise');
var util = require('../util');

var checkoutFormSelector = '#checkout-form';
var $checkoutForm = $(checkoutFormSelector);
var creditCardTypeListSelector = '.payment-card-list';
var $creditCardTypeList = $(creditCardTypeListSelector);
var creditCardNumberInputSelector = '.formfield.card-number input';
var $creditCardNumberInput = $(creditCardNumberInputSelector);
var creditCardExpirationMonthInputSelector = '.monthfield input';
var $creditCardExpirationMonthInput = $(creditCardExpirationMonthInputSelector);
var creditCardExpirationYearInputSelector = '.yearfield input';
var $creditCardExpirationYearInput = $(creditCardExpirationYearInputSelector);
var creditCardCVVInputSelector = '.form-row.cvn input';
var $creditCardCVVInput = $(creditCardCVVInputSelector);
var addressInputSelector = '.formfield.address1 input';
var $addressInput = $(addressInputSelector);
var submitPaymentSelector = '#submitOPCPayment';
var $submitPayment = $(submitPaymentSelector);
var $rewardsAccordion = $('.rewards');
var $rewardsPaymentContainer = $rewardsAccordion.find('[payment-method="REWARDS_CREDIT"]');
var $rewardsForm = $rewardsPaymentContainer.find('.rewards-form');
var $rewardsApplied = $rewardsPaymentContainer.find('.rewards-applied');

var options = {};
var dataBuffer = {};

function initialize(options) {
    $(document).on('click', submitPaymentSelector, function(event) {
    	event.preventDefault();

		var addressDeliverable = function () {
			submitPayment();
		}
	
		var addressNotDeliverable = function (addressVerifyData) {
			AddressVerificationDialog.initialize(options, 'billing', addressVerifyData);
		}
	
		if (options && options.Lob && options.Lob.AddressVerification.Enabled) {
			AddressVerificationHelper.VerifyAddress($checkoutForm, options).then(addressDeliverable, addressNotDeliverable);
		} else {
			addressDeliverable();
		}
    });

	if (options && options.Lob && options.Lob.AddressAutocomplete.Enabled) {
		$checkoutForm.off('keyup.AddressAutocomplete').on('keyup.AddressAutocomplete', 'input[type="text"]', function (event) {
			var $this = $(this);
			var $form = $this.closest('form');
			var formValidator = $form.validate();

			if (event.keyCode == 8 || event.keyCode == 46) {
				$this.parent().find('.address-autocomplete-dialog').remove();
			} else if (!formValidator.checkForm() && ($this.attr('id').indexOf('_address1') >= 0 || $this.attr('id').indexOf('_address2') >= 0)) {
				var addressAutocompleteHandler = function (addressAutocompleteData) {
					AddressAutocompleteDialog.initialize(options, addressAutocompleteData);
				}
			
				AddressVerificationHelper.AutocompleteAddress($form, $this, options).then(addressAutocompleteHandler);
			} else {
				$this.parent().find('.address-autocomplete-dialog').remove();
			}
		});
		
		$(document).off('SetAddressFromAddressAutocomplete').on('SetAddressFromAddressAutocomplete', function (event, data) {
			AddressVerificationHelper.SetAddressFromAddressVerificationDialog(data.$form, data.Address);
			$checkoutForm.find('#dwfrm_billing_billingAddress_email_emailAddress, input.phone, input.postal').trigger('blur.RewardsAccountSearch');
		});
	}

	if (options && options.Lob && options.Lob.AddressVerification.Enabled) {
		$(document).off('SetAddressFromAddressVerification').on('SetAddressFromAddressVerification', function (event, addressData) {
			AddressVerificationHelper.SetAddressFromAddressVerificationDialog($checkoutForm, addressData);
			submitPayment();
		});
	}

	$checkoutForm.off('blur.RewardsAccountSearch').on('blur.RewardsAccountSearch', '#dwfrm_billing_billingAddress_email_emailAddress, input.phone, input.postal', function(event) {
		if (dataBuffer['RewardsAccountSearchRequest'] && dataBuffer['RewardsAccountSearchRequest'].readyState !== 4) {
			dataBuffer['RewardsAccountSearchRequest'].abort();
			dataBuffer['RewardsAccountSearchRequest'] = null;
		}

		var $this = $(this);
		var field = $this.closest('[field]').attr('field');
		var value = $this.val();
		if (field && value) {
			if (!dataBuffer['RewardsData']) {
				dataBuffer['RewardsData'] = {};
			}
			dataBuffer['RewardsData'][field] = value;

			switch (field) {
				case 'emailAddress':
					$rewardsForm.find('#dwfrm_billing_rewards_rewardsEmail').val(value);
					break;
				case 'phone':
					$rewardsForm.find('.field.phonenumber').val(value);
					break;
				case 'postal':
					$rewardsForm.find('.field.zipcode').val(value);
					break;
			}

			var isRewardsDataReady = dataBuffer['RewardsData']['emailAddress'] && dataBuffer['RewardsData']['phone'] && dataBuffer['RewardsData']['postal'];
			var isRewardsNumberFieldEmpty = !$rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val();
			if (isRewardsDataReady && isRewardsNumberFieldEmpty) {
				var payload = {
					EmailAddress: dataBuffer['RewardsData']['emailAddress'],
					PhoneNumber: dataBuffer['RewardsData']['phone'],
					ZipCode: dataBuffer['RewardsData']['postal'],
				}

				if ($rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val()) {
					payload.RewardsNumber = $rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val();
				}

				dataBuffer['RewardsAccountSearchRequest'] = $.ajax({
					type: 'POST',
					url: Urls.searchForRewards,
					contentType: 'application/json',
					dataType: 'json',
					data: JSON.stringify(payload),
					success: function(data) {
						renderRewardsData(data);
					},
					complete: function(xhr) {

					}
				});
			}
		}
	});

	$rewardsForm.off('keyup.RewardsReset change.RewardsReset').on('keyup.RewardsReset change.RewardsReset', 'input', function(event) {
		var isRewardsFormPopulated = false;
		$rewardsForm.find('input').each(function() {
			if ($(this).val()) {
				isRewardsFormPopulated = true;
			}
		});
		if (isRewardsFormPopulated) {
			$rewardsForm.find('.btn.reset-echo').removeAttr('disabled');
		} else {
			$rewardsForm.find('.btn.reset-echo').attr('disabled', 'true');
		}
	});

	$rewardsForm.off('click.RewardsReset').on('click.RewardsReset', '.btn.reset-echo', function(event) {
		event.preventDefault();

		if ($rewardsApplied.find('.reward-row[status="applied"]').length > 0) {
			$rewardsApplied.find('.reward-row[status="applied"] .btn.remove').trigger('RewardsCredit.Remove', { ResetSessionData: true });
		} else {
			$.ajax({
				url: Urls.resetRewardsSessionData,
				type: 'POST'
			});
		}
		$rewardsApplied.html('');
		$rewardsForm.find('input').val('');
		$rewardsForm.find('.btn.reset-echo').attr('disabled', 'true');
		$rewardsForm.find('[match]').removeAttr('match');
		$rewardsAccordion.removeAttr('rewards-found');
	});

	$(document).off('RewardsAccountSearch').on('RewardsAccountSearch', function(event) {
		searchRewards();
	});

	updateCart('dwfrm_cart_updateCart');

	$rewardsForm.off('click.RewardsAccountSearch').on('click.RewardsAccountSearch', '.btn.search-echo', function(event) {
		event.preventDefault();
		searchRewards();
	});

	$creditCardNumberInput.off('keydown.CreditCardNumberHandler').on('keydown.CreditCardNumberHandler', function(event) {
		$creditCardTypeList.val('');
	});

	$creditCardNumberInput.off('keyup.CreditCardNumberHandler').on('keyup.CreditCardNumberHandler', function(event) {
		var $this = $(this);
		var creditCardNumber = $this.val();
		
		var selectedCard = util.getCreditCardType(creditCardNumber);
		if (selectedCard) {
			if ($creditCardNumberInput.filter(':visible').length < 1) {
				$creditCardNumberInput.val(creditCardNumber);
			}
			
			$('input[name*="_creditCard_type"]').val(selectedCard.PaymentCard);
			$('input[name*="_selectedPaymentMethodID"]').val(selectedCard.PaymentMethod);
			$creditCardTypeList.find('input[type="radio"][value="' + selectedCard.PaymentCardDisplayKey + '"]').first().prop('checked', true).trigger('change', { doReset: false });
			var $paymentCardImage = $creditCardTypeList.find('input[value="' + selectedCard.PaymentCardDisplayKey + '"]');
			var paymentCardImageUrl;
			if ($paymentCardImage && $paymentCardImage.length > 0) {
				paymentCardImageUrl = $paymentCardImage.attr('payment-card-image');
			}

			if (paymentCardImageUrl) {
				$creditCardNumberInput.css('background-image', 'url(' + paymentCardImageUrl + ')');
			}

			if (creditCardNumber.length >= 16) {
				$creditCardExpirationMonthInput.focus();
			}
			if (selectedCard.PaymentMethod == 'Alliance_Data') {
				$('.card-expiration-and-cvn').addClass('number-only');
				$('.card-expiration-and-cvn input').removeClass('required');
			}
		} else {
			$creditCardTypeList.find('input').prop('checked', false);
			$creditCardTypeList.find('option').prop('selected', false);
			$creditCardNumberInput.css('background-image', '');
			$('.card-expiration-and-cvn').removeClass('number-only');
			$('.card-expiration-and-cvn input').addClass('required');
		}
	});

	$creditCardExpirationMonthInput.off('keydown.FieldBlock').on('keypress.FieldBlock', function(event) {
		if ($(this).val().length >= 2) {
			event.preventDefault();
			event.stopPropagation();
		}
	});

	$creditCardExpirationMonthInput.off('keyup.FieldShift').on('keyup.FieldShift', function(event) {
		if ($(this).val().length >= 2) {
			$creditCardExpirationYearInput.focus();
		}
	});

	$creditCardExpirationYearInput.off('keydown.FieldBlock').on('keypress.FieldBlock', function(event) {
		if ($(this).val().length >= 2) {
			event.preventDefault();
			event.stopPropagation();
		}
	});

	$creditCardExpirationYearInput.off('keyup.FieldShift').on('keyup.FieldShift', function(event) {
		if ($(this).val().length >= 2) {
			$creditCardCVVInput.focus();
		}
	});

	$creditCardCVVInput.off('keyup.FieldShift').on('keyup.FieldShift', function(event) {
		var cvvLength = $('input[name*="_creditCard_type"]').val() == 'Amex' ? 4 : 3;

		if ($(this).val().length >= cvvLength) {
			$addressInput.focus();
		}
	});

	$(document).on('click', '.accordion-head', function(event) {
		$(this).toggleClass('active');
	});

	$(document).off('click', 'button[name$="addCoupon"]').on('click', 'button[name$="addCoupon"]', function (event) {
		event.preventDefault();
		
		var $this = $(this);
		var $container = $this.closest('.cart-coupon-code');
		var $applyButton = $container.find('.btn-coupon-code');
		$('.error.coupon-error').text('');
		
		(function() {
			$applyButton.loading({
				IsLabelShown: false,
				BackgroundColor: 'transparent'
			});

			return TPromise.resolve($.ajax({
				type: 'POST',
				url: util.ajaxUrl(Urls.onePageSubmit),
				data: {
					dwfrm_cart_addCoupon: 'dwfrm_cart_addCoupon',
					dwfrm_cart_couponCode: $('.accordion.coupon #dwfrm_cart_couponCode').val(),
					respType: 'json'
				}
			}));
		}()).then(function (response) {
			if (response.success && response.success !== CheckoutConstants.ResponseCodes.AlreadyInBasket) {				
				$(document).trigger('Cart.Update');
			} else {
				var message = response.success == CheckoutConstants.ResponseCodes.AlreadyInBasket ? Resources.COUPON_ADD_ERROR_DUP : Resources.COUPON_ADD_ERROR;
				$('<span/>', {'class': 'error coupon-error', 'text': message}).insertAfter('.accordion.coupon .cart-coupon-code .pcode-coupon');
				if ($applyButton.attr('loading')) {
					$applyButton.loading('destroy');
				}
			}
		});
	});

	$(document).off('click', 'button[name$="deleteCoupon"]').on('click', 'button[name$="deleteCoupon"]', function (event) {
		event.preventDefault();

		var $this = $(this);
		var $container = $this.closest('.coupon');
		var name = $this.attr('name');
		(function(){
			$container.loading({
				IsLabelShown: false,
				BackgroundColor: 'transparent'
			});

			return TPromise.resolve($.ajax({
				type: 'POST',
				url: util.ajaxUrl(Urls.onePageSubmit),
				data: $('form#cart-items-form').serialize() + '&' + name + '=' + name + '&respType=json'
			}));
		}()).then(function (response) {
			$(document).trigger('Cart.Update');
		});
	});

	$(document).off('click', '.order-total-payments [payment-method] .remove').on('click', '.order-total-payments [payment-method] .remove', function (e) {
		var paymentInstrumentUUID = $(e.currentTarget).closest('[payment-method').attr('uuid');
	
		if (paymentInstrumentUUID) {
			var requestPayload = {
				UUID: paymentInstrumentUUID
			}
		
			$.ajax({
				url: Urls.removePaymentInstrument,
				type: 'POST',
				contentType: 'application/json',
				data: JSON.stringify(requestPayload),
				dataType: 'json',
				success: function(data) {
					location.reload();
				}
			});
		}
	});

	$(document).off('Cart.Update').on('Cart.Update', function (event, data) {
    	updateCart('dwfrm_cart_updateCart', data);
    });

	searchRewards();
	updateCart('dwfrm_cart_updateCart');
}

var updateCartBuffer;
var updateCartXmlHttpRequestBuffer;
function updateCart(triggerElementName, options, isCartPage) {
	if (updateCartXmlHttpRequestBuffer) {
		for (var updateCartXmlHttpRequestKey in updateCartXmlHttpRequestBuffer) {
			var updateCartXmlHttpRequest = updateCartXmlHttpRequestBuffer[updateCartXmlHttpRequestKey];
			
			if (updateCartXmlHttpRequest.readyState !== 4) { 
				updateCartXmlHttpRequest.abort();
			}
		}
	}
	updateCartXmlHttpRequestBuffer = [];
	updateCartBuffer = [];

	getCartHtml(triggerElementName, options, isCartPage);
	if (!isCartPage) {
		getOrderSummaryCalloutHtml(triggerElementName, options, isCartPage);
	}
}

function getCartHtml(triggerElementName, options, isCartPage) {
    var $form = $('#checkout-form');
    updateCartBuffer['Cart'] = null;

	updateCartXmlHttpRequestBuffer['getCartHtml'] = $.ajax({
		url: Urls.onePageSubmit,
		type: "POST",
		data: triggerElementName + '=' + triggerElementName + '&selectedPaymentMethod=' + $('input[name*="_selectedPaymentMethodID"]').val() + '&format=ajax',
		success : function (data) {
			updateCartBuffer['Cart'] = data;
			getCartData(triggerElementName, options, isCartPage);
			updateCartRender(triggerElementName, options, isCartPage);
		}
	});
}

function getOrderSummaryCalloutHtml(triggerElementName, options, isCartPage) {
    updateCartBuffer['OrderSummaryCallout'] = null;

	updateCartXmlHttpRequestBuffer['getOrderSummaryCalloutHtml'] = $.ajax({
		url: Urls.getOrderSummaryCalloutContentSlot,
		dataType: 'html',
		success: function (data) {
			updateCartBuffer['OrderSummaryCallout'] = data;
			updateCartRender(triggerElementName, options, isCartPage);
		}
	});
}

function getCartData(triggerElementName, options, isCartPage) {
	updateCartBuffer['CartData'] = null;

	updateCartXmlHttpRequestBuffer['getCartData'] = $.ajax({
	    url : Urls.getCartData,
		type : "POST",
		data: {
			isCart: isCartPage
		},
	    success : function (data) {
	    	$('[has-local-delivery-shipment]').attr('has-local-delivery-shipment', data.HasLocalDeliveryShipment || false);
	    	$('[has-physical-shipment]').attr('has-physical-shipment', data.HasPhysicalShipment || false);
			$('[has-warehouse-shipment]').attr('has-warehouse-shipment', data.HasWarehouseShipment || false);
			$('[has-warehouse-shipment]').attr('has-shipping-methods', data.ShippingMethods ? data.ShippingMethods.join(' ') : false);
	    	$('[has-gift-cards]').attr('has-gift-cards', data.HasGiftCards || false);
	    	$('[has-gift-cards-only]').attr('has-gift-cards-only', data.HasGiftCardsOnly || false);
			$('[has-store-pickup-shipment]').attr('has-store-pickup-shipment', (data.Bopis && data.Bopis.HasStorePickupShipment) || false);
			$('[has-store-pickup-shipment-only]').attr('has-store-pickup-shipment-only', (data.Bopis && data.Bopis.HasStorePickupShipmentOnly) || false);
	    	$(document).trigger('CartData.Update', data);

	    	updateCartBuffer['CartData'] = data;
	    	if (data.Bopis) {
	    		updateCartBuffer['Bopis'] = data.Bopis;
	    	}
	    	
	    	if (!data.IsGlobaleValidOrInactive) {
	    		$('.section-checkout').addClass('global-e-validation-error');
	    		$('.section-checkout').find('.cart-errors').addClass('active');
	    	} else {
	    		$('.section-checkout').removeClass('global-e-validation-error');
	    		$('.section-checkout').find('.cart-errors').removeClass('active');
	    	}

	    	if (data.ShippingRestrictions && data.ShippingRestrictions.Status == 'shippingRestrictionsError') {
				if (isCartPage) {
					$('.opclogin-buttons').addClass('hide');
				} else {
					$('.payment-methods-section').hide();
				}
			} else {
				if (isCartPage) {
					$('.opclogin-buttons').removeClass('hide');
					$('.guest-checkout .error-form').remove();
				} else {
					$('.payment-methods-section').show();
				}
			}
	    	
	    	handleOrderTotal(triggerElementName, options, isCartPage);
	    }
	});
}

function handleOrderTotal(triggerElementName, options, isCartPage) {
	updateCartBuffer['PaymentInstrumentHtml'] = null;
	var removeAction = '<a class="remove">Remove</a>';
	
	if (updateCartBuffer['CartData'].OrderTotal.paymentInstruments.length > 0) {
		updateCartBuffer['PaymentInstrumentHtml'] = '';
	
		for (var paymentInstrumentKey in updateCartBuffer['CartData'].OrderTotal.paymentInstruments) {
			var paymentInstrument = updateCartBuffer['CartData'].OrderTotal.paymentInstruments[paymentInstrumentKey];
			var paymentInstrumentUUIDAttribute = 'uuid="' + paymentInstrument.UUID + '"';
			
			switch (paymentInstrument.paymentMethod.Id) {
				case PaymentMethodConstants.RewardsCredit:
		    		updateCartBuffer['PaymentInstrumentHtml'] += '<div ' + paymentInstrumentUUIDAttribute + ' payment-method="' + paymentInstrument.paymentMethod.Id + '" allow-remove="' + paymentInstrument.allowRemove + '">' +
		    			'<span class="label"><span class="name">B Rewarded</span>&nbsp;<span class="number">Reward</span></span>' +
		    			'<span class="value">' + paymentInstrument.amountDisplay + '</span>' +
		    		'</div>';
		    	break;
			
				default:
		    		updateCartBuffer['PaymentInstrumentHtml'] += '<div ' + paymentInstrumentUUIDAttribute + ' payment-method="' + paymentInstrument.paymentMethod.Id + '" allow-remove="' + paymentInstrument.allowRemove + '">' +
		    			'<span class="label"><span class="name">' + paymentInstrument.paymentMethod.Name + '</span>&nbsp;<span class="number">' + paymentInstrument.numberLastFour + '</span>' + removeAction + '</span>' +
		    			'<span class="value">' + paymentInstrument.amountDisplay + '</span>' +
		    		'</div>';
		    	break;
		    }
		}
	} else {
		$(document).trigger('GiftCard.Clear');
		$(document).trigger('RewardsCredit.Clear');
	}
	
	if (updateCartBuffer['CartData'].OrderTotal.balance < updateCartBuffer['CartData'].OrderTotal.total) {
		updateCartBuffer['BalanceDueHtml'] = '<span class="label">Balance Due</span>' +
			'<span class="value">' + updateCartBuffer['CartData'].OrderTotal.balanceDisplay + '</span>';
	}
	
	updateCartRender(triggerElementName, options, isCartPage);
}

function updateCartRender(triggerElementName, options, isCartPage) {
	if (updateCartBuffer['Cart'] && (isCartPage || (updateCartBuffer['OrderSummaryCallout'] && updateCartBuffer['CartData']))) {
		var $basketContainerHtml = $(updateCartBuffer['Cart']);
	
		if (updateCartBuffer['CartData'] && updateCartBuffer['CartData'].OrderTotal) {
			$basketContainerHtml.find('.order-total-price .value').html(updateCartBuffer['CartData'].OrderTotal.totalDisplay);
	    	$('.payment-section .order-total-price .value').html(updateCartBuffer['CartData'].OrderTotal.totalDisplay);
		
			if (updateCartBuffer['CartData'].OrderTotal.paymentInstruments.length > 0) {
				$basketContainerHtml.find('.order-totals-table').attr('payments-applied', updateCartBuffer['CartData'].OrderTotal.paymentInstruments.length);
	    		$('.payment-section').attr('payments-applied', updateCartBuffer['CartData'].OrderTotal.paymentInstruments.length);
	    	} else {
	    		$basketContainerHtml.find('.order-totals-table').removeAttr('payments-applied');
	    		$('.payment-section').removeAttr('payments-applied');
	    	}
		
			$basketContainerHtml.find('.order-totals-table').attr('balance-due', updateCartBuffer['CartData'].OrderTotal.balance);
			$('#wrapper').attr('balance-due', updateCartBuffer['CartData'].OrderTotal.balance);
	    	$('.payment-section').attr('balance-due', updateCartBuffer['CartData'].OrderTotal.balance);
		
			if (updateCartBuffer['CartData'].OrderTotal.balance > 0) {
				$('.payment-method-options').show();
				$('.payment-section-footer[data-method="PayPal"] button').hide();
			} else {
				$('.payment-method-options').hide();
				$('.payment-section-footer[data-method="PayPal"] button').show();
			}
		
	    	if (updateCartBuffer['BalanceDueHtml']) {	
	    		$basketContainerHtml.find('.order-balance-due').html(updateCartBuffer['BalanceDueHtml']);
	    		$('.payment-section .order-balance-due').html(updateCartBuffer['BalanceDueHtml']);    		
	    	} else {
	    		$('.payment-section .order-balance-due').html('');
	    	}
	    	
	    	if (updateCartBuffer['PaymentInstrumentHtml']) {
	    		$basketContainerHtml.find('.order-total-payments').html(updateCartBuffer['PaymentInstrumentHtml']);
	    		$('.payment-section .order-total-payments').html(updateCartBuffer['PaymentInstrumentHtml']);
	    	} else {
	    		$('.payment-section .order-total-payments').html('');
	    	}
		}

		$('.accordion.coupon .cart-coupon-code').html($basketContainerHtml.find('.cart-coupon-code').first().html());
		$('.basket-container').html($basketContainerHtml);
		
		if (updateCartBuffer['CartData']) {
			var orderTotalForAffirm = updateCartBuffer['CartData'].OrderTotal.balance;
			if (!orderTotalForAffirm && updateCartBuffer['CartData'].OrderTotal.adjustedMerchandizeTotalPrice) {
				orderTotalForAffirm = updateCartBuffer['CartData'].OrderTotal.adjustedMerchandizeTotalPrice;
			}
			
			if ('affirm' in window && 'ui' in affirm && 'refresh' in affirm.ui && orderTotalForAffirm >= updateCartBuffer['CartData'].AffirmMinimumOrderTotal) {
				$('.affirm-product-modal').attr('data-amount', orderTotalForAffirm * 100);
				affirm.ui.refresh();
			} else {
				$('.affirm-product-modal').attr('data-amount', 5000);
			}
		}

		if ($('.basket-container').find('.product-availability-list .not-available').length > 0) {
			$('#submitOPCPayment, #submitOPCPaymentPrivateLabel').attr('disabled', 'disabled');
			$('#errorBoxOPC').remove();
			$('#submitOPCPayment').after('<div id="errorBoxOPC" class="p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>' + Resources.Validation.Messages.Products.Availability + ':</b> ' + Resources.Validation.Messages.Products.AvailabilityExceeded + '</div>');
		} else if (updateCartBuffer['Bopis'] && updateCartBuffer['Bopis'].NumberOfBopisStoresInCart > updateCartBuffer['Bopis'].MaximumNumberOfBopisStoresInCart) {
			$('#submitOPCPayment, #submitOPCPaymentPrivateLabel').attr('disabled', 'disabled');
			$('#errorBoxOPC').remove();
			$('#submitOPCPayment').after('<div id="errorBoxOPC" class="p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>' + Resources.Validation.Messages.General.CartLimit + ':</b> ' + Resources.Validation.Messages.Bopis.MaximumStoresExceeded + '</div>');
		} else {
			$('#submitOPCPayment, #submitOPCPaymentPrivateLabel').removeAttr('disabled');
			$('#errorBoxOPC').remove();
		}
		
		if (updateCartBuffer['OrderSummaryCallout']) {	
			$('.callout-ordersummary').html(updateCartBuffer['OrderSummaryCallout']);
		}

	    if ($('.banner-cart-holder .btn-minicart').length > 0) {
			$.ajax({url : Urls.cartMiniCart, data : {desktop: '1'}, dataType: 'html', success : function(data) {
	            $('.banner-cart-holder').html(data);
	        }});
			
	    	$.ajax({url : Urls.cartMiniCart, dataType: 'html', success : function(data) {
	    		var parentMiniCart = $(".top-banner-inner-links #mini-cart");
	    		
	    		if (parentMiniCart.length > 0) {
	    			parentMiniCart.html(data);
	    		}
	        }});
		}
	    
        $.ajax({url: Urls.cartItemQuantity, data: {desktop: '1'}, dataType: 'html', success: function (data) {
        	$('.confirmation-message .item-count').html(data);
	        
	        var quantity = parseInt($('.confirmation-message .item-count').find('span').text(), 10);
	        if (!quantity) {
	            $('.checkout-buttons.mobile-only').hide();	            
			}
		}});
		
	    
	    if (triggerElementName.indexOf('deleteProduct') > -1) {
	    	$(document).trigger('refresh-required');
	    }

		if (!options || !options.EventTriggers || options.EventTriggers['update-summary']) {
			$(document).trigger('update-summary');
		}
		
		if (!options || !options.EventTriggers || options.EventTriggers['update-shipping']) {
			$(document).trigger('update-shipping');
		}
	    
	    $('[minimum-cart-total], [maximum-cart-total]').each(function(index, element) {
	    	var $this = $(this);
	    	var paymentMethodCartTotalMinimum = parseFloat($this.attr('minimum-cart-total'));
	    	var paymentMethodCartTotalMaximum = parseFloat($this.attr('maximum-cart-total'));
	    
	    	var isCartTotalMinimumValid = false;
	    	if (isNaN(paymentMethodCartTotalMinimum) || updateCartBuffer['CartData'].OrderTotal.balance >= paymentMethodCartTotalMinimum) {
	    		isCartTotalMinimumValid = true;
	    	}
	    	
	    	var isCartTotalMaximumValid = false;
	    	if (isNaN(paymentMethodCartTotalMaximum) || updateCartBuffer['CartData'].OrderTotal.balance <= paymentMethodCartTotalMaximum) {
	    		isCartTotalMaximumValid = true;
	    	}
	    	
	    	if (isCartTotalMinimumValid && isCartTotalMaximumValid) {
	    		$this.closest('.payment-method-option').attr('valid-for-balance-due', 'true');
	    	} else {
	    		$this.closest('.payment-method-option').attr('valid-for-balance-due', 'false');
	    	}
	    });
		
	    if (updateCartBuffer['CartData'] && !updateCartBuffer['CartData'].HasPhysicalShipment && $('.single.shipping-address-type-selector input:checked').val() != SiteConstants.AddressTypes.Customer) {
	    	$('.single.shipping-address-type-selector input[value="' + SiteConstants.AddressTypes.Customer + '"]').click();
	    }
		
		tooltip.init({
			items: '.shipping-method-tooltip',
			tooltipClass: 'shipping-method-tooltip'
		}, $('[shipping-method]'));

		tooltip.init({
			items: '.tooltip'
		});

		triggerSrFocus();
		$(document).trigger('QuantityStepper.TargetFocus');
	    $(document).trigger('Cart.UpdateSuccess');
	}
}

function triggerSrFocus() {
	$('[role="alert"]').last().focus();
}

function submitPayment(data) {
	$submitPayment.loading({
		IsImageShown: false,
		Label: Resources.Loading.Checkout.PlacingOrder
	});
	$submitPayment.attr('disabled', true);
	$('#errorBoxOPC').remove();

	$checkoutForm.validate().form();	
	$checkoutForm.validate().settings.ignore = [];
	
	if ($checkoutForm.valid()) {
		setOPCPaymentData($checkoutForm, $submitPayment.attr('name')).then(function(response) {
			var data;
			try {
				data = JSON.parse(response);
			} catch (e) {
				data = response;
			}

			if (data.hasOwnProperty('status') && data.status === 'success') {
				window.location.href = Urls.confirmationPage;
			} else if (data.hasOwnProperty('status') && data.status === 'affirmRedirect') {
				var _affirm_config = {
					public_api_key: data.affirm.PublicApiKey,
					script: data.affirm.Script
				};
				(function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,_affirm_config,"affirm","checkout","ui","script","ready");
				
				affirm.checkout(JSON.parse(data.affirm.AffirmCheckout));
				affirm.checkout.post();
				affirm.ui.ready(
					function() {
						affirm.ui.error.on("close", function(){
							// window.location.replace(data.affirm.RedirectUrl);
						});
					}
				);
			} else if (data.hasOwnProperty('status') && data.status === 'shippingRestrictionsError'){
				var popup = require('../popup');
				var payload = JSON.parse(data.payload);

				var list = $.map(payload.list, function (el) {
					return '<li>' + el.name + ':<br>' + el.message + '</li>';
				}).join('');
				
				popup.init({
					title: payload.title,
					content: payload.message + '<ul class="shipping-restrictions-popup__list">' + list + '</ul>',
				}).showPopup();
			} else if (data.hasOwnProperty('status') && data.status === 'ValidationError') {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>Validation Error:</b> ' + message + '</div>');
			} else {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>Payment Declined:</b> ' + message + '</div>');
			}
			
			$submitPayment.loading('destroy');
			$submitPayment.attr('disabled', false);
		}).catch(function(response) {
			if (response.status == 302 && response.responseText) {
				location.href = response.responseText;
			}
		});
	} else {
		$submitPayment.loading('destroy');
		$submitPayment.attr('disabled', false);
	}
}

var setOPCPaymentData = function ($form, name) {
	var payload = $form.serialize() + '&' + name + '=' + name;
	var $checkoutVariables = $('.button-and-terms input[type="hidden"]');
	$checkoutVariables.each(function() {
		var $this = $(this);
		payload += '&' + $this.attr('name') + '=' + $this.val();
	});

    return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.setOpcAddress),
        data: payload
    }));
};

function searchRewards() {
	if (dataBuffer['RewardsAccountSearchRequest'] && dataBuffer['RewardsAccountSearchRequest'].readyState !== 4) {
		dataBuffer['RewardsAccountSearchRequest'].abort();
		dataBuffer['RewardsAccountSearchRequest'] = null;
	}
	$rewardsApplied.html('');

	var payload = {
		EmailAddress: $rewardsForm.find('#dwfrm_billing_rewards_rewardsEmail').val(),
		PhoneNumber: $rewardsForm.find('.field.phonenumber').val(),
		ZipCode: $rewardsForm.find('.field.zipcode').val(),
	}

	if ($rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val()) {
		payload.RewardsNumber = $rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val();
	}

	$('.btn.search-echo').loading({
		IsLabelShown: false,
		BackgroundColor: 'transparent'
	});

	dataBuffer['RewardsAccountSearchRequest'] = $.ajax({
		type: 'POST',
		url: Urls.searchForRewards,
		contentType: 'application/json',
		dataType: 'json',
		data: JSON.stringify(payload),
		success: function(data) {
			renderRewardsData(data);
		},
		complete: function(xhr) {
			if ($('.btn.search-echo').attr('loading')) {
				$('.btn.search-echo').loading('destroy');
			}
		}
	});
}

function renderRewardsData(data) {
	if (data.Match && data.Rewards) {
		if (data.Rewards.rewardsNumber) {
			$rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val(data.Rewards.rewardsNumber);
		} else {
			$rewardsForm.find('#dwfrm_billing_rewards_rewardsNumber').val('');
		}
		if (data.Rewards.email) {
			$rewardsForm.find('#dwfrm_billing_rewards_rewardsEmail').val(data.Rewards.email);
		} else {
			$rewardsForm.find('#dwfrm_billing_rewards_rewardsEmail').val('');
		}
		if (data.Rewards.phone) {
			$rewardsForm.find('.field.phonenumber').val(data.Rewards.phone);
		} else {
			$rewardsForm.find('.field.phonenumber').val('');
		}
		if (data.Rewards.zipValue) {
			$rewardsForm.find('.field.zipcode').val(data.Rewards.zipValue);
		} else {
			$rewardsForm.find('.field.zipcode').val('');
		}

		if (data.Match.RewardsEmail) {
			$rewardsForm.find('.rewards-email-container').attr('match', 'true');
		} else {
			$rewardsForm.find('.rewards-email-container').attr('match', 'false');
		}
		if (data.Match.RewardsPhone) {
			$rewardsForm.find('.input-container.phone').attr('match', 'true');
		} else {
			$rewardsForm.find('.input-container.phone').attr('match', 'false');
		}
		if (data.Match.RewardsZip) {
			$rewardsForm.find('.input-container.zip').attr('match', 'true');
		} else {
			$rewardsForm.find('.input-container.zip').attr('match', 'false');
		}
		
		var rewardsRowTemplate = _.template($('#template-rewards-credit-row-echo').html(), TemplateConstants.Lodash.Settings);
		var status = '';
		var message = '';
		if (parseFloat(data.Rewards.creditAmount) <= 0) {
			status = 'message';
			message = 'no rewards available';
		} else if ($rewardsForm.closest('[is-applied]').attr('is-applied') == 'true') {
			status = 'applied';
		} else {
			status = 'pending';
		}

		var additionalData = '<table class="rewards-information">' +
								'<tr><td>Address</td><td>' + data.Rewards.address + '</td></tr>' +
								'<tr><td>City</td><td>' + data.Rewards.city + '</td></tr>' +
								'<tr><td>State</td><td>' + data.Rewards.state + '</td></tr>' +
								'<tr><td>Zip</td><td>' + data.Rewards.zipValue + '</td></tr>' +
								'<tr><td>Country</td><td>' + data.Rewards.country + '</td></tr>' +
								'<tr><td>Phone</td><td>' + data.Rewards.phone + '</td></tr>' +
							'</table><table class="rewards-points">' +
								'<tr><td>Points</td><td>' + data.Rewards.pointBalance + '</td></tr>' +
							'</table>';

		$rewardsApplied.html(rewardsRowTemplate({
			Status: status,
			RewardsNumber: data.Rewards.rewardsNumber,
			Name: data.Rewards.fullName,
			Email: data.Rewards.email,
			RewardsAvailable: data.Rewards.creditAmountFormatted.replace('.00', ''),
			RewardsExpirationDate: data.Rewards.creditExpirationDate || 'n/a',
			Message: message,
			AdditionalData: additionalData
		}));
		if ($rewardsAccordion.find('header .rewards-points').length) {
			$rewardsAccordion.find('header .rewards-points').text(data.Rewards.pointBalance);
		}

		$rewardsAccordion.attr('rewards-found', 'true');
		$rewardsForm.find('.btn.reset-echo').removeAttr('disabled');
		if (!$('.accordion.rewards .accordion-head').hasClass('active')) {
			$('.accordion.rewards .accordion-head').addClass('active');
		}
	} else {
		$rewardsAccordion.removeAttr('rewards-found');
		$rewardsApplied.html('<p class="no-results">no rewards found</p>');
	}
}

exports.init = function (optionsParameter) {
	options = optionsParameter;

	if (options && options.Lob && options.Lob.AddressVerification.Enabled) {
		AddressVerificationDialog = require('../../../../app_storefront_core/cartridge/js/Lob/AddressVerification/AddressVerificationDialog');
	}

	if (options && options.Lob && options.Lob.AddressAutocomplete.Enabled) {
		AddressAutocompleteDialog = require('../../../../app_storefront_core/cartridge/js/Lob/AddressAutocomplete/AddressAutocompleteDialog');
	}

	GiftCard.initialize($.extend({ LoadingIndicator: {
		IsLabelShown: false,
		BackgroundColor: 'transparent'
	}}, options));

	RewardsCredit.initialize($.extend({ LoadingIndicator: {
		IsLabelShown: false,
		BackgroundColor: 'transparent'
	}}, options));

	ApplePay.initialize(options);

    initialize(options);
};